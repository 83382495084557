<template>
	<!-- Table Container Card -->

	<b-card no-body>
		<b-row class="m-2 top-header">
			<!-- Date picker -->
			<b-col cols="12" md="6" class="d-flex align-items-center">
				<h3 class="pr-1 mb-0">
					SETTER
					<span class="ml-1 success-text"
						>{{ activeCount }} Active and {{ inactiveCount }} Inactive</span
					>
				</h3>
			</b-col>
			<!-- Search -->
			<b-col
				cols="12"
				md="6"
				class="d-flex justify-content-end align-items-center"
			>
				<div>
					<b-form-input
						v-model="searchQuery"
						class="d-inline-block"
						placeholder="Search..."
					/>
				</div>
				<b-button variant="primary" v-b-modal.addSetter class="mr-1 ml-2">
					Add
				</b-button>
			</b-col>
		</b-row>
		<b-row class="m-2">
			<!-- Search -->
			<b-col
				cols="12"
				md="12"
				class="d-flex justify-content-end align-items-center"
			>
				<div class="ml-1 success">{{ emptySlots }} Empty Slots</div>
				<div class="ml-1 danger">{{ occupiedSlots }} Occupied Slots</div>
			</b-col>
		</b-row>
		<div v-if="machine_info != null && machine_info.length > 0">
			<div v-for="info in machine_info" v-bind:key="info.id">
				<setter-list
					:hatchery_device_id="
						info.hatchery_device ? info.hatchery_device.id : 0
					"
					:id="info.id"
					:setterName="info.machine_id"
					:deviceId="
						info.hatchery_device ? info.hatchery_device.device_id : 'No Device'
					"
					:humidity="info.hatchery_device ? info.hatchery_device.humidity : 0"
					:temperature="info.hatchery_device ? info.hatchery_device.temp : 0"
					:availableEgg="info.available_egg_capacity"
					:trayCapacity="info.tray_capacity"
					:eggCapacity="info.egg_capacity"
					:status="info.machine_status"
				/>
			</div>
		</div>
		<div v-else class="d-flex flex-column align-items-center py-5">
			<b-img
				:src="require('@/assets/images/svg/machine/no-setter.svg')"
				class="mr-2"
			/>
			<h4 class="mt-2 Secondary">No setter machine added yet</h4>
		</div>

		<!-- setter modal -->
		<b-modal
			id="addSetter"
			title="Add Setter Details"
			size="lg"
			ok-title="Submit"
			@ok="addSetterMachine()"
		>
			<form ref="form" @submit="addSetterMachine" method="post">
				<b-form-group label-for="i-first-name" label="Setter ID">
					<b-form-input
						id="setter_name"
						placeholder=""
						v-model="machineData.machine_id"
					/>
				</b-form-group>

				<b-form-group label-for="egg-capacity" label="Egg Capacity">
					<b-form-input
						id="egg_capacity"
						placeholder=""
						v-model="machineData.egg_capacity"
					/>
				</b-form-group>

				<b-form-group label-for="No. of tray" label="No of tray">
					<b-form-input
						id="tray_no"
						placeholder=""
						v-model="machineData.tray_capacity"
					/>
				</b-form-group>
			</form>
		</b-modal>
	</b-card>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BFormGroup,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
	BFormDatepicker,
	BImg,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { BASE_URL } from "@core/common/constants";
import SetterList from "../useSetterList.vue";
import axiosIns from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BFormGroup,
		BTooltip,
		BFormDatepicker,
		vSelect,
		SetterList,
		BImg,
	},
	data() {
		return {
			hatchery_Device_id: {},
			temperature: null,
			humidity: null,
			searchQuery: null,
			machineType: "setter",
			machine_info: null,
			emptySlots: null,
			occupiedSlots: null,
			device_id: null,
			setter_name: null,
			inactiveCount: null,
			activeCount: null,
			machineData: {
				machine_id: null,
				egg_capacity: null,
				tray_capacity: null,
				machine_type: "setter",
			},
		};
	},
	watch: {
		deep: true,
		immediate: true,
		getHatcheryId() {
			this.hatcheryId = this.$route.params.hatcheryId;
			this.getSetterMachine();
		},
	},
	methods: {
		getSetterMachine() {
			const hatcheryId = this.$route.params.hatcheryId;
			axiosIns
				.get(`web/hatchery/${hatcheryId}/getMachine/${this.machineType}`)
				.then((response) => {
					this.machine_info = response.data.all_machine;
					this.inactiveCount = response.data.inactiveCount;
					this.activeCount = response.data.activeCount;
					this.occupiedSlots = response.data.occupied_slots;
					this.emptySlots = response.data.empty_slots;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		addSetterMachine() {
			let formData = new FormData();
			this.machineData.hatchery_id = this.$route.params.hatcheryId;
			let form_data = this.objectToFormData(this.machineData, formData);

			axiosIns
				.post(`web/add-machine`, form_data)
				.then((response) => {
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "success",
							text: `Machine Successfully Added `,
						},
					});
					this.getSetterMachine();
				})
				.catch((error) => {
					const data = error.response.data.message;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "danger",
							text: data,
						},
					});
				});
		},
	},

	created() {
		this.hatcheryId = this.$route.params.hatcheryId;
		if (this.hatcheryId) {
			this.getSetterMachine();
		}
	},
};
</script>

<style lang="scss">
.top-header {
	border-bottom: 1px solid #38c06c;
	padding: 1rem 0rem;
}
.success-text {
	color: #38c06c;
	font-size: 18px;
}

.danger-text {
	color: #f793a4;
	font-size: 18px;
}
.success {
	background-color: #d7f3e1;
	font-size: 14px;
	color: #38c06c;
	display: flex;
	align-items: center;
	margin-right: 1rem;
	width: 200px;
	padding: 15px 15px;
	height: 33px;
	border-radius: 16px;
	justify-content: center;
}
.danger {
	background-color: #5dadfd33;
	font-size: 14px;
	color: #5dadfd;
	display: flex;
	align-items: center;
	width: 200px;
	padding: 1rem 1rem;
	height: 33px;
	justify-content: center;
	border-radius: 16px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
